import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { modalAction, loginStatusAction, loaderAction, userInfoAction, globalDataAction } from "../../../../actions";
import axios from "axios";
import { ProgressBar } from "react-bootstrap";
// import axios from "axios";
// import { JQDatePicker, createDate, getDuration, handleNumberInputs, useFetch } from "../utilities";


const PrescriptionUpload = ({ modalAction, isLoggedIn, loginStatusAction, compCode, loaderAction, userInfoAction, userInfo, globalData, globalDataAction }) => {

    const [file, setFile] = useState({});
    const [imgURL, setImgURL] = useState('');
    const [progress, setProgress] = useState({ isComplete: false, currentProgress: 0 });
    const prescription = globalData.prescription;

    useEffect(() => {						
		window.renderVenoBox();
	},[prescription.src])
  
    function handleChange(event) {
        let target = event.target.files[0];
        var url = URL.createObjectURL(target);
        setFile(target);     
        setImgURL(url);
    }
  
    function handleSubmit(event) {
        event.preventDefault()
        if (!file.name) return alert('Please select a file.');
        
        // ----------------------------------------------------------------- Turn on from this line to see example with server.
        // const url = 'http://localhost:3000/upload';
        // const formData = new FormData();
        // formData.append('image', file);
        // formData.append('imgName', file.name);
        // const data = { title: 'Prescription Upload', image: formData.get('image'), imgName: formData.get('imgName') };
        // const config = {
        //     headers: { 'content-type': 'multipart/form-data' },
        //     onUploadProgress: (e) => {
        //         const percentProgress = Math.round((e.loaded / e.total) * 100);
        //         setProgress(pre => ({ ...pre, currentProgress: percentProgress }));
        //     }
        // };

        // axios.post(url, data, config).then((res) => {
        //     if (res.status === 200) {
        //         setProgress(pre => ({ ...pre, isComplete: true }));
        //         globalDataAction({ prescription: { name: file.name, src: imgURL } });
        //         setFile({});
        //         setImgURL('');
        //         setProgress({ isComplete: false, currentProgress: 0 });
        //     } else {
        //         alert('Something went wrong. please try again.');
        //         setFile({});
        //     }            
        // });
        // ----------------------------------------------------------------- Turn on upto this line to see example with server.
        
        
        // ----------------------------------------------------------------- Keep below lines off while example with server is on.
        globalDataAction({ prescription: { name: file.name, src: imgURL } });        
        setFile({});
        setImgURL('');
    }   
    
    return (
        <section className="presc-upload-box">
            <h1><i className="bx bxs-cloud-upload"></i> Upload Prescription</h1>
            <div className="presc-content">
                {
                !prescription.name ?
                <div className="card">
                    <div className="card-header">
                        <h4>Upload</h4>
                    </div>
                    <div className="card-body">
                        <h5>Please upload images of a valid prescription from your doctor.</h5>
                        <div>
                            {imgURL ? 
                            <div className="d-flex gap-4 py-4">
                                <div className="img-preview">
                                    <i className='bx bx-x delete' onClick={() => {setFile({}); setImgURL('')}}></i>
                                    <img src={imgURL} alt="Uploaded content"/>
                                </div>
                                <h5 style={{fontFamily: 'Lato', fontSize: '1em', lineHeight: '1.6em'}}>{file.name}</h5>
                            </div>
                            :
                            <div className="upload-box">
                                <label className="upload-item" htmlFor="img-input">
                                    <i className="bx bx-file"></i>
                                    <p className="mb-0">click to select</p>
                                </label>
                            </div>}
                            <input type="file" className="d-none" onChange={handleChange} accept="image/png, image/jpeg" name="image" id="img-input"/>
                        </div>
                        {progress.currentProgress === 0 || progress.currentProgress === 100 ? '' : <ProgressBar variant="info" now={progress.currentProgress} style={{margin: '1rem 0 1.5rem'}} />}
                        <p>Always upload clean version of the prescription to get better results.</p>
                        {/* {progress.isComplete ? <p className="text-danger">Successfully uploaded your prescription.</p> : ''} */}
                        {/* {progress.isComplete ? 
                        <button onClick={() => modalAction('PRESCRIPTION_MODAL', false)} className="add-to-cart d-block ms-auto" style={{padding: '0.8em 3em'}}>Close</button> :  */}
                         <button type="submit" className="add-to-cart d-block ms-auto" onClick={handleSubmit} style={{padding: '0.8em 3em'}}>
                            {(progress.currentProgress === 0 || progress.currentProgress === 100) ? 'Upload File' : 'Uploading...'}
                         </button>
                         {/* }    */}
                    </div>
                </div>
                :
                <div className="card">
                    <div className="card-header">
                        <h4>Upload</h4>
                    </div>
                    <div className="card-body">
                        <h5>Your uploaded prescription.</h5>
                        <div>
                            <div className="d-flex gap-4 py-4">
                                <div className="img-preview">
                                    <div className="zoom-img">
                                        <i class='bx bx-search search'></i>
                                    </div>
                                    <i className='bx bx-x delete' onClick={() => globalDataAction({ prescription: {} })}></i>
                                    <a className="popup-img venobox vbox-item d-flex justify-content-center" href={prescription.src} data-gall="myGallery">
                                        <img src={prescription.src} alt="Uploaded content"/>
                                    </a>
                                </div>
                                <h5 style={{fontFamily: 'Lato', fontSize: '1em', lineHeight: '1.6em'}}>{prescription.name}</h5>
                            </div>
                        </div> 
                        <p className="text-danger" onClick={() => window.renderVenoBox()}>Your Prescription is uploaded Successfully.</p>
                        <button onClick={() => modalAction('PRESCRIPTION_MODAL', false)} className="add-to-cart d-block ms-auto" style={{padding: '0.8em 3em'}}>Close</button> 
                    </div>
                </div>}

                <div className="card">
                    <div className="card-header">
                        <h4>Guide for Valid prescription</h4>
                    </div>
                    <div className="card-body card-body presc-img-box">
                        <img className="img-fluid" src="/assets/img/ePharma/presc.png" alt="prescription example" style={{boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px'}} />
                    </div>
                    <div className="card-footer">
                        <h5>Doctor signature & stamp</h5>
                        <p className="mb-0">The prescription with a signature and/or stamp of the doctor is considered as valid.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

const mapStateToPrescriptionUpload = (state) => {
    return { isLoggedIn: state.isLoggedIn, compCode: state.compCode, userInfo: state.userInfo, globalData: state.globalData };
}

export default connect(mapStateToPrescriptionUpload, {modalAction, loginStatusAction, loaderAction, userInfoAction, globalDataAction})(PrescriptionUpload);


